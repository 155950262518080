import { render, staticRenderFns } from "./ClubCards.vue?vue&type=template&id=50b60812&scoped=true&"
import script from "./ClubCards.vue?vue&type=script&lang=js&"
export * from "./ClubCards.vue?vue&type=script&lang=js&"
import style0 from "./ClubCards.vue?vue&type=style&index=0&id=50b60812&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b60812",
  null
  
)

export default component.exports