<template>
  <div>
    <client-only>
      <div v-if="context.isAcademyDirection">
        <AtomContainer>
          <client-only>
            <div class="mt-6 md:mt-10 -mb-14 md:-mb-20 pb-14 md:pb-20 md:border-b border-gray-500">
              <AtomTitle tag="h2" class-name="-md:text-base mb-5">Присоединяйтесь <br />к команде мечты!</AtomTitle>
              <AtomText class="mt-3 mb-10 md:mt-6 md:mb-8">
                Наши партнеры – Академия Технологий Фитнеса в сотрудничестве с ведущими специалистами отрасли делятся
                знаниями и опытом их применения на практике в клубах Encore Fitness.
              </AtomText>
              <AtomButton class="-md:w-full md:!px-20" tag="button" @click="openModalAcademy()">
                Узнать больше
              </AtomButton>
            </div>
          </client-only>
        </AtomContainer>
      </div>
      <div v-else class="">
        <AtomContainer v-if="context.vacancies.length">
          <client-only>
            <Accordion>
              <AccordionItem v-for="item in context.vacancies" :key="item.id">
                <template slot="accordion-trigger">
                  <AtomTitle tag="h3" class-name="-md:text-base">{{ item.title }}</AtomTitle>
                </template>
                <template slot="accordion-content">
                  <div class="my-10 md:my-16">
                    <MoleculeList v-for="list in getBlocks(item)" :key="list.label" :list="list" />

                    <div class="md:grid grid-cols-2">
                      <div class="col-span-1 col-start-2">
                        <AtomButton class="-md:w-full md:!px-20" tag="button" @click="openModal(item)">
                          ОТКЛИКНУТЬСЯ
                        </AtomButton>
                      </div>
                    </div>
                  </div>
                </template>
              </AccordionItem>
            </Accordion>
          </client-only>
        </AtomContainer>
        <div
          v-else
          class="container text base md:text-lg text-gray-300 mt-40 md:mt-56 mb-2 text-center flex items-center justify-center"
        >
          К сожалению, сейчас нет открытых вакансий по указанным параметрам.
        </div>
      </div>
    </client-only>
    <section class="pt-28 md:pt-40 md:pb-5">
      <div class="container">
        <div class="md:grid grid-cols-12">
          <div class="md:col-start-3 md:col-span-10 md:w-fit flex flex-col gap-2 mb-8">
            <AtomButton class="text-balance" tag="a" href="https://academytechfit.ru/">
              Образовательный центр &#8203; «Академия Технологий Фитнеса»
            </AtomButton>
            <AtomText class="text-center">
              Программы профессиональной переподготовки и курсы повышения квалификации.
            </AtomText>
          </div>
        </div>
        <div class="gradient rounded-[10px] px-3 py-10 md:py-44">
          <div class="md:grid grid-cols-12">
            <div class="md:col-start-3 md:col-span-8">
              <AtomTitle tag="h2" class-name="-md:text-base mb-5">Не нашли подходящую <br />вакансию?</AtomTitle>
              <AtomText class="mt-3 mb-10 md:mt-6 md:mb-8"
                >Обязательно напишите и&nbsp;присоединяйтесь к&nbsp;команде Encore&nbsp;Fitness!</AtomText
              >
              <AtomButton class="-md:w-full md:!px-20" tag="button" @click="openModal"> ОТПРАВИТЬ РЕЗЮМЕ </AtomButton>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalVacancy v-if="vacancy" :vacancy="vacancy" @close="closeModal" />
    <ModalAcademy v-if="academy" @close="closeModalAcademy" />
  </div>
</template>

<script>
import Accordion from '../../atoms/accordion/AtomAccordion'
import AccordionItem from '../../atoms/accordion/AtomAccordionItem'
import AtomButton from '../../atoms/AtomButton'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'
import AtomContainer from '../../atoms/layouts/AtomContainer'
import MoleculeList from '../../molecules/layouts/MoleculeList'
import ModalVacancy from '../../organisms/modals/Vacancy.vue'
import ModalAcademy from '../../organisms/modals/Academy.vue'

export default {
  name: 'OrganismCareerAccordion',
  components: {
    AtomContainer,
    AtomText,
    AtomButton,
    AtomTitle,
    Accordion,
    AccordionItem,
    MoleculeList,
    ModalVacancy,
    ModalAcademy,
  },
  builder: {
    component: 'career',
  },
  props: ['context'],
  data() {
    return {
      vacancy: null,
      academy: false,
    }
  },
  methods: {
    getBlocks(v) {
      const responsibilities = getList(v.responsibilities)
      const requirements = getList(v.requirements)
      const conditions = getList(v.conditions)
      return [
        responsibilities.length && {
          label: 'Обязанности',
          list: responsibilities,
        },
        requirements.length && {
          label: 'Требования',
          list: requirements,
        },
        conditions.length && {
          label: 'Условия',
          list: conditions,
        },
      ].filter(Boolean)
      function getList(text) {
        if (!text) return []
        return text
          .split('\n')
          .filter(Boolean)
          .map(text => ({ text }))
      }
    },
    getClubs(v) {
      return v.clubs.map(club => ({ text: club.title, slug: club.slug }))
    },
    openModal(vacancy) {
      if (vacancy) {
        this.vacancy = {
          id: vacancy.id,
          title: vacancy.title,
          image: vacancy.image,
          clubs: vacancy?.clubs,
          club: vacancy?.clubs?.slug || false,
        }
      } else {
        this.vacancy = {}
      }
    },
    closeModal() {
      this.vacancy = null
    },
    openModalAcademy() {
      this.academy = true
    },
    closeModalAcademy() {
      this.academy = false
    },
  },
}
</script>

<style scoped>
.text-balance {
  text-wrap: balance;
}
</style>
