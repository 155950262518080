<template>
  <div class="relative" v-bind="{ [MENU_ATTR_CONTAINER]: true }">
    <layout
      v-for="(block, i) in components"
      :key="i"
      :layout="getLayout(block)"
      :class="{ [`typo-${block.component}`]: true }"
      v-bind="getMenuAnchorProps(block)"
    >
      <component :is="getComponent(block)" v-bind="adaptProps(block)" :context="context" />
    </layout>
    <StickyMenu v-if="menu.length" :links="menu" />
  </div>
</template>

<script>
import { componentsAtom, TYPES_ATOM } from './atoms.js'
import { componentsCore, StickyMenu, TYPES_CORE } from './core.js'
import { componentsMolecule, TYPES_MOLECULE } from './molecules.js'
import { componentsOrganism, TYPES_ORGANISM } from './organism.js'

const DEFAULT_BLANK_COMPONENT = 'Blank'

export default {
  name: 'Builder',
  components: {
    ...componentsAtom,
    ...componentsOrganism,
    ...componentsMolecule,
    ...componentsCore,
  },
  props: ['blocks', 'context'],
  data: () => {
    return {
      MENU_ATTR_CONTAINER: StickyMenu.MENU_ATTR_CONTAINER,
    }
  },
  computed: {
    menu() {
      return this.blocks
        .filter(b => this.isMenuComponent(b.component))
        .filter(b => Boolean(b.props.title))
        .map(this.adaptProps)
    },
    components() {
      return this.blocks.filter(b => !this.isMenuComponent(b.component))
    },
  },
  methods: {
    getComponent(block) {
      if (this.componentExists(block)) {
        return TYPE_TO_COMPONENT[block.component]
      }
      return DEFAULT_BLANK_COMPONENT
    },
    adaptProps(block) {
      const props = block.props || {}
      return props
    },
    componentExists(block) {
      return Boolean(block) && block.component in TYPE_TO_COMPONENT
    },
    getLayout(block) {
      if (this.componentExists(block)) {
        return block.layout || this.getComponentBuilderSettings(block).layout
      }
    },
    getComponentBuilderSettings(block) {
      try {
        return this.$options.components[this.getComponent(block)].builder
      } catch (e) {
        return {}
      }
    },
    isMenuComponent(name) {
      return name === 'sticky-menu'
    },
    getMenuAnchorProps(block) {
      const index = this.blocks.indexOf(block)
      const before = this.blocks[index - 1] || {}
      const after = this.blocks[index + 1] || {}
      const props = {}
      if (this.isMenuComponent(before.component)) {
        props[StickyMenu.MENU_ATTR_REF] = before.props.hash
      }
      if (!this.hasMenuEnding) {
        const afterIsEmptyMenuItem = after && this.isMenuComponent(after) && !after.props.title
        const isLastComponent = this.components.indexOf(block) === this.components.length - 1
        if (afterIsEmptyMenuItem || isLastComponent) {
          props[StickyMenu.MENU_ATTR_ENDING] = this.hasMenuEnding = true
        }
      }
      return props
    },
  },
}

const TYPE_TO_COMPONENT = {
  ...TYPES_ATOM,
  ...TYPES_CORE,
  ...TYPES_ORGANISM,
  ...TYPES_MOLECULE,
}
</script>
