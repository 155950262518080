import OrganismAboutEvolution from '../organisms/builder/AboutEvolution'
import OrganismClubCards from '../organisms/builder/ClubCards'
import OrganismClubFitness from '../organisms/builder/ClubFitness'
import OrganismFitnessDynamic from '../organisms/builder/Fitness'
import OrganismClubsList from '../organisms/builder/ClubsList'
import OrganismClubSpaDescription from '../organisms/builder/ClubSpaDescription'
import OrganismClubTrainers from '../organisms/builder/ClubTrainers'
import OrganismContactsClubs from '../organisms/builder/ContactsClubs'
import OrganismFormLearnMore from '../organisms/builder/FormLearnMore'
import OrganismFormWriteUs from '../organisms/builder/FormWriteUs'
import OrganismFranchiseContacts from '../organisms/builder/FranchiseContacts'
import OrganismMainClubs from '../organisms/builder/MainClubs'
import OrganismMainFitness from '../organisms/builder/MainFitness'
import OrganismMyzoneCard from '../organisms/builder/MyzoneCard'
import OrganismAccordion from '../organisms/builder/OrganismAccordion'
import OrganismBigImage from '../organisms/builder/OrganismBigImage'
import OrganismBoxImageTwoCol from '../organisms/builder/OrganismBoxImageTwoCol'
import OrganismContacts from '../organisms/builder/OrganismContacts'
import OrganismFeature from '../organisms/builder/OrganismFeature'
import OrganismInfographic from '../organisms/builder/OrganismInfographic'
import OrganismSpaContacts from '../organisms/builder/OrganismSpaContacts'
import OrganismTitleImage from '../organisms/builder/OrganismTitleImage'
import OrganismSpaClubsList from '../organisms/builder/SpaClubsList'
import OrganismTimeTable from '../organisms/builder/TimeTable'
import OrganismTrainersList from '../organisms/builder/TrainersList'
import OrganismApps from '../organisms/builder/OrganismApps'
import OrganismCareerAccordion from '../organisms/career/OrganismAccordion'

export const TYPES_ORGANISM = {
  [OrganismAccordion.builder.component]: OrganismAccordion.name,
  [OrganismTitleImage.builder.component]: OrganismTitleImage.name,
  [OrganismInfographic.builder.component]: OrganismInfographic.name,
  [OrganismBigImage.builder.component]: OrganismBigImage.name,
  [OrganismClubsList.builder.component]: OrganismClubsList.name,
  [OrganismContacts.builder.component]: OrganismContacts.name,
  [OrganismClubCards.builder.component]: OrganismClubCards.name,
  [OrganismClubSpaDescription.builder.component]: OrganismClubSpaDescription.name,
  [OrganismFeature.builder.component]: OrganismFeature.name,
  [OrganismClubFitness.builder.component]: OrganismClubFitness.name,
  [OrganismMainFitness.builder.component]: OrganismMainFitness.name,
  [OrganismClubTrainers.builder.component]: OrganismClubTrainers.name,
  [OrganismTrainersList.builder.component]: OrganismTrainersList.name,
  [OrganismSpaClubsList.builder.component]: OrganismSpaClubsList.name,
  [OrganismSpaContacts.builder.component]: OrganismSpaContacts.name,
  [OrganismTimeTable.builder.component]: OrganismTimeTable.name,
  [OrganismFranchiseContacts.builder.component]: OrganismFranchiseContacts.name,
  [OrganismContactsClubs.builder.component]: OrganismContactsClubs.name,
  [OrganismBoxImageTwoCol.builder.component]: OrganismBoxImageTwoCol.name,
  [OrganismAboutEvolution.builder.component]: OrganismAboutEvolution.name,
  [OrganismMainClubs.builder.component]: OrganismMainClubs.name,
  [OrganismFormLearnMore.builder.component]: OrganismFormLearnMore.name,
  [OrganismFormWriteUs.builder.component]: OrganismFormWriteUs.name,
  [OrganismMyzoneCard.builder.component]: OrganismMyzoneCard.name,
  [OrganismApps.builder.component]: OrganismApps.name,
  [OrganismCareerAccordion.builder.component]: OrganismCareerAccordion.name,
  [OrganismFitnessDynamic.builder.component]: OrganismFitnessDynamic.name,
}

export const componentsOrganism = {
  [OrganismAccordion.name]: OrganismAccordion,
  [OrganismTitleImage.name]: OrganismTitleImage,
  [OrganismInfographic.name]: OrganismInfographic,
  [OrganismBigImage.name]: OrganismBigImage,
  [OrganismClubsList.name]: OrganismClubsList,
  [OrganismContacts.name]: OrganismContacts,
  [OrganismClubCards.name]: OrganismClubCards,
  [OrganismClubSpaDescription.name]: OrganismClubSpaDescription,
  [OrganismFeature.name]: OrganismFeature,
  [OrganismClubFitness.name]: OrganismClubFitness,
  [OrganismMainFitness.name]: OrganismMainFitness,
  [OrganismClubTrainers.name]: OrganismClubTrainers,
  [OrganismTrainersList.name]: OrganismTrainersList,
  [OrganismSpaClubsList.name]: OrganismSpaClubsList,
  [OrganismSpaContacts.name]: OrganismSpaContacts,
  [OrganismTimeTable.name]: OrganismTimeTable,
  [OrganismFranchiseContacts.name]: OrganismFranchiseContacts,
  [OrganismContactsClubs.name]: OrganismContactsClubs,
  [OrganismBoxImageTwoCol.name]: OrganismBoxImageTwoCol,
  [OrganismAboutEvolution.name]: OrganismAboutEvolution,
  [OrganismMainClubs.name]: OrganismMainClubs,
  [OrganismFormLearnMore.name]: OrganismFormLearnMore,
  [OrganismFormWriteUs.name]: OrganismFormWriteUs,
  [OrganismMyzoneCard.name]: OrganismMyzoneCard,
  [OrganismApps.name]: OrganismApps,
  [OrganismCareerAccordion.name]: OrganismCareerAccordion,
  [OrganismFitnessDynamic.name]: OrganismFitnessDynamic,
}
