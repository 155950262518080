<template>
  <AtomContainer v-if="rows.length" class="mb-10">
    <div
      v-for="item in rows"
      :key="item.id"
      class="flex flex-wrap md:grid grid-cols-10 border-t last:border-b border-gray-500 py-2 md:py-4 md:mr-5 md:gap-x-5"
    >
      <AtomText class="-md:pb-2 -md:w-full md:col-span-3" :content="item.col1" />
      <AtomText v-if="item.type === 'phone'" class="-md:w-1/2 -md:pb-2 -md:pr-1 md:col-span-3" :content="item.col2" />
      <div v-else-if="item.type === 'address'" class="-md:w-1/2 -md:pb-2 -md:pl-1 flex md:col-span-3">
        <AtomButton v-if="item.link" tag="a" :href="item.link" class="-md:self-end btn-outline btn-lg btn-icon mr-3">
          <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 22.978L10.1577 22.4037C15.6887 17.5742 18.5 13.3572 18.5 9.65045C18.5 4.40112 14.4029 0.650452 9.5 0.650452C4.59705 0.650452 0.5 4.40112 0.5 9.65045C0.5 13.3572 3.31131 17.5742 8.84227 22.4037L9.5 22.978ZM9.5 20.3139C4.80661 16.084 2.5 12.4997 2.5 9.65045C2.5 5.54705 5.66411 2.65045 9.5 2.65045C13.3359 2.65045 16.5 5.54705 16.5 9.65045C16.5 12.4997 14.1934 16.084 9.5 20.3139ZM9.5 4.65045C12.2614 4.65045 14.5 6.88903 14.5 9.65045C14.5 12.4119 12.2614 14.6505 9.5 14.6505C6.73858 14.6505 4.5 12.4119 4.5 9.65045C4.5 6.88903 6.73858 4.65045 9.5 4.65045ZM6.5 9.65045C6.5 7.9936 7.84315 6.65045 9.5 6.65045C11.1569 6.65045 12.5 7.9936 12.5 9.65045C12.5 11.3073 11.1569 12.6505 9.5 12.6505C7.84315 12.6505 6.5 11.3073 6.5 9.65045Z"
              fill="#282929"
            />
          </svg>
        </AtomButton>
        <AtomText class="-md:hidden" :content="item.col2" />
      </div>
      <div class="-md:w-1/2 md:grid md:grid-cols-2 md:col-span-4 md:gap-x-5">
        <AtomText v-if="item.type === 'address'" :content="item.col2" class="md:hidden" />
        <AtomText :content="item.col3" />
        <AtomText :content="item.col4" />
      </div>
    </div>
    <div v-if="isKidsPage" class="flex">
      <AtomButton class="w-full md:block md:max-w-max md:self-end md:ml-auto" @click="scrollToHash"
        >ПОСЕТИТЬ КЛУБ</AtomButton
      >
    </div>
  </AtomContainer>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomText from '../../atoms/AtomText'
import AtomContainer from '../../atoms/layouts/AtomContainer'

// const FOOTER_ATTR_REF = 'data-sticky-menu-ref'
// data-scroll-id="footer_pin"

export default {
  name: 'OrganismContacts',
  components: { AtomText, AtomContainer, AtomButton },
  builder: {
    component: 'club-contacts',
  },
  computed: {
    club() {
      const slug = this.$route?.query?.club || this.$route.params?.id
      return this.$store.getters['modules/content/clubs'].find(c => c.slug === slug)
    },
    isKidsPage() {
      return this.$route.name === 'fitness_kids-clubs'
    },
    rows() {
      try {
        if (this.isKidsPage) {
          return this.club.contacts.filter(
            item => item?.col1?.includes('Офис продаж') || item?.type?.includes('address'),
          )
        } else {
          return this.club.contacts
        }
      } catch (e) {
        return []
      }
    },
  },
  methods: {
    scrollToHash() {
      try {
        const menuRef = document?.querySelector('[data-scroll-id="footer_pin"] #footer')
        this.$root.$loco.scrollTo(menuRef)
      } catch (error) {}
    },
  },
}
</script>
