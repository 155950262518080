<template>
  <div class="pt-24 md:pt-40 md:pb-16">
    <AtomTitle
      class="px-3 md:px-0 mb-3"
      :class="narrow ? 'md:!mb-[-20px]' : ''"
      :class-name="narrow ? 'h2' : '!pt-[0.2em]'"
      :tag="narrow ? 'h2' : 'h1'"
      :sup="`(${cards.length})`"
      animation="rotation"
      >Фитнес</AtomTitle
    >
    <OrganismGrid v-if="isKidsPage" :narrow="narrow" :cards="kidsCards" />
    <OrganismGrid v-else :narrow="narrow" :cards="cards" />
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'
import OrganismGrid from '../../organisms/OrganismGrid'

export default {
  name: 'Fitness',
  components: {
    AtomTitle,
    OrganismGrid,
  },
  props: {
    fitnessTesting: {
      type: Boolean,
      default: true,
    },
    groupTraining: {
      type: Boolean,
      default: true,
    },
    miniGroups: {
      type: Boolean,
      default: true,
    },
    kidsClub: {
      type: Boolean,
      default: true,
    },
    myzoneEncore: {
      type: Boolean,
      default: true,
    },
    personalTraining: {
      type: Boolean,
      default: true,
    },
    fitnessTestingTitle: {
      type: String,
      default: 'InBody',
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      kidsCards: [
        {
          title: 'Дневник достижений',
          image: 'fitness/kids-clubs/k1.jpg',
          imageMin: 'fitness/kids-clubs/k1.webp',
          // tag: [
          //   {
          //     name: 'Персональный тренинг',
          //   },
          // ],
        },

        {
          title: 'Мастер-классы, мероприятия, праздники',
          image: 'fitness/kids-clubs/k2.jpg',
          imageMin: 'fitness/kids-clubs/k2.webp',
        },
        {
          title: 'От 2х до 13 лет',
          image: 'fitness/kids-clubs/k3.jpg',
          imageMin: 'fitness/kids-clubs/k3.webp',
        },
        {
          title: 'Персональные, групповые, секции',
          image: 'fitness/kids-clubs/k4.jpg',
          imageMin: 'fitness/kids-clubs/k4.webp',
        },
        {
          title: 'Развивающие программы',
          image: 'fitness/kids-clubs/k5.jpg',
          imageMin: 'fitness/kids-clubs/k5.webp',
        },
        {
          title: 'Фитнес-лагерь',
          image: 'fitness/kids-clubs/k6.jpg',
          imageMin: 'fitness/kids-clubs/k6.webp',
        },
      ],
    }
  },
  computed: {
    cards() {
      return [
        this.personalTraining && {
          title: 'индивидуальный подход и&nbsp;безопасность',
          image: 'fitness/f1.jpg',
          imageMin: 'fitness/min/f1.webp',
          // to: '/fitness/personal-training',
          tag: [
            {
              name: 'Персональный тренинг',
            },
          ],
        },
        this.groupTraining && {
          title: 'видов тренировок',
          image: 'fitness/f2.jpg',
          imageMin: 'fitness/min/f2.webp',
          titleBig: '60',
          titleBigTag: 'big',
          // to: '/fitness/group-training',
          tag: [
            {
              name: 'Групповой тренинг',
            },
          ],
        },
        this.miniGroups && {
          title: 'человек с одинаковым уровнем подготовки',
          image: 'fitness/f3.jpg',
          imageMin: 'fitness/min/f3.webp',
          titleBig: '3-5',
          titleBigTag: 'big',
          // to: '/fitness/mini-group',
          tag: [
            {
              name: 'Мини-группы',
            },
          ],
        },
        this.kidsClub && {
          title: 'бассейн, секции, творческие и обучающие программы',
          image: 'fitness/f4.jpg',
          imageMin: 'fitness/min/f4.webp',
          // to: '/fitness/kids-clubs',
          tag: [
            {
              name: 'Детский фитнес',
            },
          ],
        },
        this.fitnessTesting && {
          title: 'с квалифицированными специалистами',
          image: 'fitness/f5.jpg',
          imageMin: 'fitness/min/f5.webp',
          titleBig: this.fitnessTestingTitle,
          titleBigTag: 'h2',
          to: '/fitness/testing',
          tag: [
            {
              name: 'Фитнес-тестирование',
            },
          ],
        },
        this.myzoneEncore && {
          title: 'точность показателей в реальном времени',
          image: 'fitness/f6.jpg',
          imageMin: 'fitness/min/f6.webp',
          titleBig: '99',
          titleBigTag: 'big',
          className: 'custom-title',
          titleBigSup: '%',
          to: '/fitness/myzone',
          tag: [
            {
              name: 'Myzone®',
            },
          ],
        },
      ].filter(Boolean)
    },
    isKidsPage() {
      return this.$route.name === 'fitness_kids-clubs'
    },
  },
}
</script>
