<template>
  <div
    class="-md:border-x print:border-b-2 border-gray-500"
    data-scroll-id="no_sticky_header"
    data-scroll
    @swiped-left="addDay"
    @swiped-right="reduceDay"
  >
    <div
      ref="sticky"
      :class="{ 'sticky top-0': !isDesktop() && hasTrainings }"
      class="relative z-30 bg-gray-600 md:bg-white print:!static print:border-l print:border-gray-500 print:mt-3"
    >
      <div class="grid grid-custom-cols-9 relative">
        <button
          class="inline-flex absolute p-3 mt-[-27px] top-1/2 md:m-0 md:p-0 left-0 md:static items-center justify-start md:border-r md:border-b md:border-t border-gray-500 print:!static print:!mt-0 print:border-t print:border-b print:border-r"
          @click="reduceDay"
        >
          <svg
            class="print:!hidden"
            width="17"
            height="30"
            viewBox="0 0 17 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 29L0.999999 15L16 1" stroke="#282929" stroke-linecap="square" />
          </svg>
        </button>
        <div v-for="day in days" :key="day.key">
          <AtomHeadDay
            :day="day.day"
            :date="day.date"
            :class="{ 'bg-gray-600': day.today, 'text-gray-500 print:text-gray-100': day.old, 'bg-white': !day.today }"
          />
        </div>
        <button
          class="inline-flex absolute p-3 mt-[-27px] top-1/2 md:m-0 md:p-0 right-0 md:static items-center justify-end md:border-b md:border-t border-gray-500 print:!hidden"
          @click="addDay"
        >
          <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 29L16 15L1 1" stroke="#282929" stroke-linecap="square" />
          </svg>
        </button>
      </div>
    </div>

    <div
      v-if="!hasTrainings"
      class="container text-base md:text-lg text-gray-300 my-10 md:my-40 text-center flex items-center justify-center min-h-[60px]"
    >
      К сожалению, сейчас нет тренировок по указанным параметрам.
    </div>
    <template v-if="hasTrainings">
      <div
        v-for="time in filteredTimes"
        :key="time"
        class="line grid grid-custom-cols-9 md:border-b border-b-gray-500 min-h-[140px] md:min-h-[180px] md:mt-[-1px] print:border-l print:border-gray-500 print:min-h-[130px]"
      >
        <AtomTime :time="time" />
        <div
          v-for="day in days"
          :key="day.key"
          class="item md:ml-[-1px] -md:border-b border-gray-500"
          :class="{ 'item-full': timetables[day.key] && timetables[day.key][time] }"
        >
          <div v-if="timetables[day.key] && timetables[day.key][time]" class="day">
            <MoleculeDay
              v-for="(item, index) in timetables[day.key][time]"
              :key="index"
              :timetable="item"
              @click="openModal(item)"
            />
          </div>
        </div>
        <Modal v-if="modalItem" :title="modalItem.name" :img="modalItem.image" @close="modalItem = null">
          <template #controls>
            <div class="flex flex-wrap gap-3">
              <!-- <AtomButton v-if="modalItem.meet" tag="a" :href="modalItem.cabinet" class="btn btn-sm group">
                По записи
                <svg
                  class="ml-[10px]"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="group-hover:stroke-white"
                    d="M5 7.625H9.375"
                    stroke="#282929"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="group-hover:stroke-white"
                    d="M5 10.125H7.7375"
                    stroke="#282929"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="group-hover:stroke-white"
                    d="M6.25 3.75H8.75C10 3.75 10 3.125 10 2.5C10 1.25 9.375 1.25 8.75 1.25H6.25C5.625 1.25 5 1.25 5 2.5C5 3.75 5.625 3.75 6.25 3.75Z"
                    stroke="#282929"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="group-hover:stroke-white"
                    d="M10 2.51251C12.0812 2.62501 13.125 3.39376 13.125 6.25001V10C13.125 12.5 12.5 13.75 9.375 13.75H5.625C2.5 13.75 1.875 12.5 1.875 10V6.25001C1.875 3.40001 2.91875 2.62501 5 2.51251"
                    stroke="#282929"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </AtomButton> -->
              <AtomButton v-if="modalItem.paid" tag="span" class="btn btn-sm btn-outline disabled">
                Платное
                <svg
                  class="ml-[10px]"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.42188 8.95615C5.42188 9.7624 6.04063 10.4124 6.80938 10.4124H8.37812C9.04687 10.4124 9.59062 9.84365 9.59062 9.14365C9.59062 8.38115 9.25937 8.1124 8.76562 7.9374L6.24687 7.0624C5.75312 6.8874 5.42188 6.61865 5.42188 5.85615C5.42188 5.15615 5.96562 4.5874 6.63437 4.5874H8.20312C8.97187 4.5874 9.59062 5.2374 9.59062 6.04365"
                    stroke="#282929"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M7.5 3.75V11.25" stroke="#282929" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="#282929"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </AtomButton>
            </div>
          </template>
          <div>
            <div class="-md:border-gray-500 -md:border-b -md:border-t -md:pb-5 -md:pt-3 -md:mb-10">
              <AtomTitle tag="h3" class="mb-2 md:mb-[30px]" class-name="-md:!text-base" :content="modalItem.day" />
              <div class="flex flex-col md:flex-row md:items-center md:mb-[60px]">
                <AtomTitle
                  tag="h2"
                  class="-md:mb-4"
                  :content="`${modalItem.time}${modalItem.timeEnd ? ` – ${modalItem.timeEnd}` : ''}`"
                />
                <AtomButton
                  v-if="modalItem.href"
                  tag="a"
                  :href="modalItem.href"
                  class="btn btn-lg btn-fill uppercase -md:w-full md:ml-5"
                >
                  Записаться
                </AtomButton>
              </div>
            </div>
            <div class="flex flex-col gap-y-10 md:gap-y-10 mb-10 md:mb-10">
              <MoleculeModalRowButton v-for="item in modalTags" :key="item.name" :item="item" />
            </div>
            <MoleculeModalRowText v-if="modalItem.text.text" :item="modalItem.text" />
            <MoleculeModalRowProgress v-for="item in modalItem.progress" :key="item.title" :item="item" />
          </div>
          <template #footer>
            <MoleculeModalRowButton v-for="item in footer" :key="item.name" :item="item" />
          </template>
        </Modal>
      </div>
    </template>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/AtomTitle'
import AtomHeadDay from '../../atoms/timetable/AtomHeadDay'
import AtomTime from '../../atoms/timetable/AtomTime'
import MoleculeModalRowButton from '../../molecules/modals/MoleculeModalRowButton'
import MoleculeModalRowProgress from '../../molecules/modals/MoleculeModalRowProgress'
import MoleculeModalRowText from '../../molecules/modals/MoleculeModalRowText'
import MoleculeDay from '../../molecules/timetable/MoleculeDay'
import Modal from '../../templates/layouts/Modal'

export default {
  name: 'OrganismTimeTable',
  components: {
    AtomTitle,
    AtomButton,
    AtomHeadDay,
    AtomTime,
    MoleculeDay,
    MoleculeModalRowButton,
    MoleculeModalRowText,
    MoleculeModalRowProgress,
    Modal,
  },
  props: ['isday', 'timetables', 'times', 'days', 'date', 'footer'],
  data() {
    return {
      modalItem: null,
      stickyHeader: {
        boundaryBottom: 0,
        boundaryTop: 0,
      },
    }
  },
  computed: {
    hasTrainings() {
      if (!this.days.length) return false
      const hasTimes = Boolean(this.times.length)
      if (this.days.length !== 1) return hasTimes
      const [day] = this.days
      return hasTimes && Boolean(this.timetables[day.key])
    },
    modalTags() {
      return (this.modalItem?.tags || []).map(tag => {
        return {
          ...tag,
          controls: tag.controls.map(c => ({ ...c, click: this.closeModal })),
        }
      })
    },
    filteredTimes() {
      if (this.days && this.days.length === 1) {
        const [day] = this.days
        const timetableTimes = this.timetables[day.key]
        return this.times.filter(time => time in timetableTimes)
      }
      return this.times
    },
  },
  mounted() {
    this.calcBoundaries()
    new ResizeObserver(() => {
      this.calcBoundaries()
      this.recalcOnFirstScroll = true
    }).observe(this.$el)
    this.$root.$loco.on('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.handleScroll)
    this.unmounted = true
  },
  methods: {
    updateDay(diff) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          date: this.$dayjs(this.date, 'YYYY-MM-DD', true)
            .add(diff, this.isday ? 'day' : 'week')
            .format('YYYY-MM-DD'),
        },
      })
    },
    addDay() {
      this.updateDay(1)
    },
    reduceDay() {
      this.updateDay(-1)
    },
    closeModal() {
      this.modalItem = null
    },
    async openModal(item) {
      const url = `/uploads/${item.name}.webp`

      try {
        const {
          request: { responseURL },
        } = await this.$axios.head(url)

        this.modalItem = { ...item, image: responseURL }
      } catch (e) {
        this.modalItem = item
      }
    },
    handleScroll(e) {
      if (!this.isDesktop()) return
      const y = e.scroll.y
      if (this.recalcOnFirstScroll) {
        this.calcBoundaries(y)
        this.recalcOnFirstScroll = false
      }
      if (!this.hasTrainings || y < this.stickyHeader.boundaryTop) {
        this.$refs.sticky.style.cssText = ''
        return
      }
      const offset = Math.min(
        y - this.stickyHeader.boundaryTop,
        this.stickyHeader.boundaryBottom - this.stickyHeader.boundaryTop,
      )
      this.$refs.sticky.style.cssText = `transform: translateY(${offset}px)`
    },
    calcBoundaries(y = this.$root.$loco.scroll.instance.delta?.y || 0) {
      if (!this.$el || !this.$refs.sticky) {
        if (!this.unmounted) setTimeout(this.calcBoundaries, 100)
        return
      }
      const rectRoot = this.$el.getBoundingClientRect()
      const rectSticky = this.$refs.sticky.getBoundingClientRect()
      const boundaryTop = rectRoot.y + y
      this.stickyHeader.boundaryTop = boundaryTop
      this.stickyHeader.boundaryBottom = boundaryTop + rectRoot.height - rectSticky.height
    },
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
  },
}
</script>

<style lang="postcss" scoped>
.item {
  @apply print:border-r print:border-b print:border-gray-500;
}
/* .cell + .cell:last-child {
  @apply border-t-0;
} */

.cell {
  @apply -md:first:border-t-0;
}

.day {
  @apply -md:border-0;
}
@screen md {
  .grid-custom-cols-9 {
    grid-template-columns: 32px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 32px;
  }
}
@media print {
  .grid-custom-cols-9 {
    grid-template-columns: 32px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.line {
  position: relative;
  @apply break-inside-avoid-page;
  &:before {
    @apply bg-white md:content-[''] print:!hidden;
    width: 32px;
    height: 2px;
    position: absolute;
    right: 0;
    bottom: -1px;
  }
}
</style>
