<template>
  <component
    :is="card.to ? 'nuxt-link' : 'div'"
    ref="card"
    :to="card.to"
    class="card overflow-hidden block relative py-5 px-4 rounded-[10px] bg-gray-600 transition ease-in-out duration-500"
  >
    <div class="img-mask absolute left-0 right-0 top-0 bottom-0">
      <AtomImage
        class="img-hover absolute top-0 left-0 right-0 bottom-0"
        :class="{ ['-md:hidden']: card.imageMin || card.strapiImageMin }"
        :src="card.image"
        :strapi-image-object="card.strapiImage"
      />
      <AtomImage
        v-if="card.imageMin || card.strapiImageMin"
        class="img-hover md:hidden absolute left-0 right-0 top-0 bottom-0"
        :src="card.imageMin"
        :strapi-image-object="card.strapiImageMin"
      />
    </div>
    <AtomTag v-for="i in card.tag" :key="i.name" tag="span" class="shadow-linear z-50" :content="i.name" />
    <div class="text-wrap flex flex-col items-center justify-center text-center -md:min-h-[340px] md:aspect-[427/453]">
      <AtomTitle :tag="card.titleBigTag" :class="card.className" class="hidden md:block" :sup="card.titleBigSup">{{
        card.titleBig
      }}</AtomTitle>
      <AtomTitle tag="h3" class="hidden md:block" :content="card.title" />
    </div>
  </component>
</template>

<script>
import AtomImage from '../atoms/AtomImage'
import AtomTag from '../atoms/AtomTag'
import AtomTitle from '../atoms/AtomTitle'

export default {
  name: 'MoleculeCard',
  components: {
    AtomTitle,
    AtomTag,
    AtomImage,
  },
  props: ['card'],
}
</script>

<style lang="postcss" scoped>
.card {
  &:hover {
    .img-mask,
    .img-hover {
      @apply top-0;
    }
    .img-hover {
      scale: 1.03;
    }
    /* .img-mask {
      scale: 1.03;
    } */
    .tag {
      @apply text-white;
      &:before {
        transform: translateZ(0);
      }
    }
  }
  .tag {
    @apply md:h-[32px];
    &:before {
      @apply absolute left-0 w-full z-[-1] bottom-[-0.1rem] content-[''] bg-gray-100;
      height: calc(100% + 0.2rem);
      transform: translate3d(0, 100%, 0);
      transition: transform 0.3s var(--ease-out);
    }
  }
}
.img-mask {
  transition: top 1s var(--ease);
  @apply md:top-full w-full h-full z-30 overflow-hidden;
}
.img-hover {
  scale: 1;
  transition: all 1s var(--ease);
  background-size: cover;
  background-position: center;
  @apply w-full md:top-[-100%] h-full z-30 object-cover;
}
</style>
