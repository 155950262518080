<template>
  <div class="md:container">
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-9 md:col-start-3">
        <AtomTitle tag="h2" class="-md:mb-[-20px] px-3 md:px-0 mb-3">Тренеры</AtomTitle>
        <div
          class="group_cards_trainers px-[12px] md:px-0 max-w-[calc(100vw_-_12px)] overflow-visible grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-5"
          data-scroll
          data-scroll-id="group_cards_trainers"
        >
          <nuxt-link
            v-for="trainer in trainersToShow"
            :key="trainer.id"
            ref="card"
            :to="`/team/${clubSlug}/trainer/${trainer.id}`"
            class="last:pr-[12px] pb-5 md:pb-0 w-full card-view"
          >
            <MoleculeTrainerCard :card="trainer" />
          </nuxt-link>
          <nuxt-link
            v-if="trainers.length > countToShow"
            ref="cardMore"
            class="group_cards_else w-full cursor-pointer flex flex-col before:md:pt-[69%] after:md:pb-[69%] before:md:content-[''] before:md:float-left after:md:content-[''] after:md:block after:md:clear-left justify-between overflow-hidden relative md:py-5 md:px-4 rounded-[10px] md:bg-gray-600 -md:mt-[-20px] -md:mb-[60px]"
            :to="`/team/${clubSlug}`"
          >
            <div
              class="-md:relative -md:p-2 before:-md:content-[''] before:-md:float-left after:-md:content-[''] after:-md:block after:-md:clear-left flex flex-col h-[90%] rounded-[10px] bg-gray-600 justify-center items-center w-full"
            >
              <div class="relative">
                <AtomTag
                  tag="span"
                  class="self-start !text-gray-100 !border-gray-100 !bg-gray-600"
                  content="все тренеры"
                />
                <sup
                  class="absolute top-0 left-[100%] mt-[-12px] ml-[-10px] md:mt-[-20px] md:ml-[-12px] text-[12px] md:text-base"
                  >+{{ trainers.length - countToShow }}</sup
                >
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTag from '../../atoms/AtomTag'
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeTrainerCard from '../../molecules/team/MoleculeCard'

export default {
  name: 'ClubTrainers',
  builder: {
    component: 'club-trainers',
  },
  components: {
    AtomTitle,
    AtomTag,
    MoleculeTrainerCard,
  },
  data() {
    return {
      trainers: [],
      randomTrainers: [],
      countToShow: 5,
    }
  },

  computed: {
    trainersToShow() {
      return this.randomTrainers.slice(0, this.countToShow)
    },
    clubSlug() {
      return this.$route.params?.id
    },
  },

  watch: {
    // '$route.params.id': 'updateTrainers',
    trainers() {
      this.randomTrainers = this.trainers.slice(0).sort(() => (Math.random() < 0.5 ? 1 : -1))
    },
  },

  mounted() {    
    this.updateTrainers()
    this.countToShow = this.isDesktop() ? 5 : 3
    this.$root.$loco?.on('scroll', this.handleScroll)
  },

  beforeDestroy() {
    this.$root.$loco?.off('scroll', this.handleScroll)
  },

  methods: {
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },

    handleScroll(data) {
      if (window.innerWidth < 960) return
      if (!this.$refs.card || !this.$refs.card.length) return
      const speeds = this.isDesktop() ? [3, 6, 4.5] : [3, 4.5]
      const group = data.currentElements.group_cards_trainers
      if (!group) return
      const cards = this.$refs.card.concat(this.$refs.cardMore ? [this.$refs.cardMore] : [])
      cards.forEach((card, i) => {
        const top = (0.5 - group.progress) * 100 * speeds[i % speeds.length]
        card.$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${top}, 0, 1)`
      })
    },

    filterTrainers(trainersData) {
      const trainersWithPhoto = trainersData.filter(trainer => trainer.photo)

      return this.$route.name?.includes('fitness_kids-clubs')
        ? trainersWithPhoto.filter(trainer => trainer.kids)
        : trainersWithPhoto
    },

    async updateTrainers() {
      if (!this.clubSlug) return

      try {
        const trainersData =
          this.$store.state.modules.content[`fitbase/${this.clubSlug}/trainers`] ||
          (await this.$store.dispatch('modules/content/getContent', {
            id: `fitbase/${this.clubSlug}/trainers`,
          })) ||
          []

        this.trainers = this.filterTrainers(trainersData)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style lang="postcss">
.group_cards_else {
  .tag {
    @apply grid md:h-[32px] z-0 relative bg-transparent -md:shadow-linear;
    transition: background 0.3s var(--ease-out);
    &:before {
      @apply absolute left-0 w-full z-[1] bottom-[-0.1rem] md:content-[''] bg-gray-100;
      height: calc(100% + 0.2rem);
      transform: translate3d(0, 100%, 0);
      transition: transform 0.3s var(--ease-out);
    }
  }
  &:hover {
    .tag {
      @apply -md:bg-transparent md:!bg-gray-100;
      transition-delay: 0.2s;
      span {
        @apply md:!text-white;
      }
      &:before {
        transform: translateZ(0);
      }
    }
  }
}
.group_cards_trainers {
  transition: opacity 1s var(--cubic-bezier), transform 1s var(--cubic-bezier) 0.3s;
  @apply md:translate-y-1/4 translate-y-0 opacity-30;
  &.is-inview {
    @apply translate-y-0 opacity-100;
  }
  .card-view {
    @apply -md:first:mt-10;
  }
}
</style>
