<template>
  <div class="pt-24 md:pt-40 md:pb-16">
    <AtomTitle
      class="px-3 md:px-0 mb-3"
      :class="narrow ? 'md:!mb-[-20px]' : ''"
      :class-name="narrow ? 'h2' : '!pt-[0.2em]'"
      :tag="narrow ? 'h2' : 'h1'"
      :sup="`(${card.length})`"
      animation="rotation"
      >{{ title }}</AtomTitle
    >
    <OrganismGrid :narrow="narrow" :cards="adaptedCards" />
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'
import OrganismGrid from '../../organisms/OrganismGrid'

export default {
  name: 'FitnessDynamic',
  components: {
    AtomTitle,
    OrganismGrid,
  },
  builder: {
    component: 'club-fitness-block',
    layout: 'gap-content-gap',
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    card: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      narrow: true,
    }
  },
  computed: {
    adaptedCards() {
      return this.card.map(card => {
        return {
          to: card.link,
          strapiImageMin: card.imageMobile,
          strapiImage: card.imageDesktop,
          tag: card.tags && [{ name: card.tags }],
          title: card.title,
          titleBigTag: getTitileBigTag(card.titleBig?.size),
          titleBigSup: card.titleBig?.sup,
          titleBig: card.titleBig?.text,
          className: card.titleBig?.sup && 'custom-title',
        }
      })

      function getTitileBigTag(size) {
        if (size == 'large') {
          return 'big'
        } else if (size == 'medium') {
          return 'h1'
        } else {
          return 'h2'
        }
      }
    },
  },
}

// type Card = {
//   link?: string,
//   imageDesktop?: Object,
//   imageMobile?: Object,
//   titleBig?: {
//     size?: 'large' | 'medium',
//     text: string,
//     sup?: string,
//     style?: string
//   },
//   title?: string,
//   tags?: { name: string }[],
// }[]
</script>
