<template>
  <div class="big-image">
    <div class="relative">
      <AtomImageParallax
        v-if="!Boolean(!video && !video?.url)"
        class="w-full aspect-[296/150] md:aspect-auto md:h-[560px]"
        img-classes="w-full"
        :strapi-image-object="image"
      />
      <AtomVideo
        v-else-if="video && video.url"
        class="big-image-video !w-full min-h-[238px] object-cover md:h-[560px]"
        :src="video.url"
        :fit="true"
      />
    </div>
    <div class="md:grid md:grid-cols-10">
      <AtomTitleSimple class="mb-4 pt-3 md:col-span-4 md:pt-10" :description="title" animation="rotation" />
      <div class="col-start-6 col-end-9 md:mt-10">
        <div class="paragraph-list">
          <AtomText
            v-for="i in description"
            :key="i.name"
            class="paragraph first:pt-0 py-3 md:py-10 md:first:pt-0"
            :description="i.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomVideo from '../../atoms/AtomVideo'
import AtomText from '../../atoms/builder/AtomText'
import AtomTitleSimple from '../../atoms/builder/AtomTitleSimple'

export default {
  name: 'OrganismBigImage',
  builder: {
    component: 'big-image',
    layout: 'gap-content',
  },
  components: {
    AtomTitleSimple,
    AtomVideo,
    AtomText,
    AtomImageParallax,
  },
  props: ['description', 'title', 'image', 'video'],
}
</script>

<style lang="postcss" scoped>
.paragraph {
  @apply border-b border-gray-500 last:border-0;
}
.big-image {
  &:hover {
    .big-image-video {
      @apply opacity-100;
    }
  }
  .big-image-video {
    transition: opacity 0.4s var(--cubic-bezier);
  }
}
</style>
