<template>
  <AtomContent>
    <div class="grid grid-cols-10">
      <div class="club-cards col-span-10 md:col-span-9">
        <AtomTitle class="mb-5" tag="h2" content="Клубные карты" />
        <client-only>
          <swiper ref="swiper" :key="key" class="swiper-cards -md:mx-[-6px]" :options="options" @slideChange="onSwipe">
            <swiper-slide v-for="(card, i) in cards" :key="`${i}_${card.id}`" class="slide-item">
              <div
                class="flex md:hidden slide-title -md:px-[6px]"
                :class="{ active: activeIndex == i && !disabledMobile }"
                @click="changeSlide(i)"
              >
                {{ card.title }}
              </div>
              <div class="relative mb-5 -md:px-[6px] -md:mt-3">
                <p class="text-white absolute top-1 left-2 md:top-3 md:left-3 text-sm md:text-[30px] md:leading-[115%]">
                  {{ card.title }}
                </p>
                <AtomImage v-if="card.image" :strapi-image-object="card.image" />
              </div>
              <div ref="slide" class="relative -md:px-[6px]">
                <div
                  v-if="tooltip.index === i && tooltip.text"
                  :style="`transform: translate(${tooltip.x}px, ${tooltip.y}px)`"
                  class="flex tooltip absolute -md:inset-x-0 bg-gray-100 rounded-[5px] text-gray-500 p-3 text-sm z-10 max-w-[260px]"
                >
                  <span>{{ tooltip.text }}</span>
                  <span class="md:hidden self-baseline p-1" @click="hideTooltip()">
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7.73956 8M1.26044 8L8 1" stroke="#CFD1D6" stroke-linecap="round" />
                    </svg>
                  </span>
                </div>
                <p
                  v-for="(service, si) in card.listServices"
                  :key="si"
                  class="text-sm md:text-base border-b border-gray-500 last:border-0 py-[5px] md:py-[10px]"
                  @mousemove="hasNote(service, card) ? handleMousemove($event, i, card[service.note]) : hideTooltip()"
                  @mouseleave="hideTooltip()"
                  @touchstart="hasNote(service, card) && handleTouch($event, i, card[service.note])"
                >
                  {{ service.text }}{{ getNotes(service, card) }}
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </client-only>
      </div>
    </div>
  </AtomContent>
</template>

<script>
import AtomContent from '../../atoms/AtomContent'
import AtomImage from '../../atoms/AtomImage'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'ClubCards',
  builder: {
    component: 'club-cards',
  },
  components: {
    AtomContent,
    AtomImage,
    AtomTitle,
  },
  data() {
    return {
      key: 0,
      disabledMobile: false,
      cards: [],
      activeIndex: 0,
      tooltip: {
        text: null,
        index: -1,
        x: 0,
        y: 0,
      },
      options: {
        slidesPerView: 2,
        speed: 1000,
        enabled: true,
        allowTouchMove: true,
        shortSwipes: true,
        breakpoints: {
          960: {
            slidesPerView: 3,
            enabled: false,
            allowTouchMove: false,
          },
        },
      },
    }
  },
  async fetch() {
    const endpoint = this.isKidsClub ? '/api/kids-cards' : `/api/cards/${this.clubName}`
    const response = await this.$axios.get(endpoint)
    this.cards = response.data || []
  },
  computed: {
    clubName() {
      return this.$route?.params?.id
    },
    isKidsClub() {
      return this.$route.path.includes('kids-')
    },
  },
  watch: {
    cards() {
      this.redefineOptions()
    },
  },
  mounted() {
    this.redefineOptions()
  },
  methods: {
    redefineOptions() {
      this.disabledMobile = window.innerWidth < 960 && this.cards.length <= 2
      this.options.allowTouchMove = this.options.enabled = !this.disabledMobile
      this.key++
    },
    changeSlide(i) {
      if (this.$refs.swiper.$swiper.realIndex === i) {
        if (i !== 0) {
          this.activeIndex = i
          this.$refs.swiper.$swiper.slideTo(i - 1)
        } else {
          this.activeIndex = 0
        }
      } else {
        if (this.$refs.swiper.$swiper.realIndex != i) {
          this.activeIndex = i
        } else {
          this.activeIndex = this.$refs.swiper.$swiper.realIndex
        }
        this.$refs.swiper.$swiper.slideTo(i)
      }
    },
    onSwipe() {
      this.activeIndex = this.$refs.swiper.$swiper.realIndex
    },
    handleTouch(e, index, text) {
      const x = e.touches[0].clientX
      const y = e.touches[0].clientY
      this.showTooltip(index, x, y, text)
    },
    handleMousemove: throttle(function (e, index, text) {
      this.showTooltip(index, e.clientX, e.clientY, text)
    }, 30),
    showTooltip(index, x, y, text) {
      const slide = this.$refs.slide[index]
      if (!slide) return
      const box = slide.getBoundingClientRect()
      const tooltipX = x - box.x
      const tooltipY = y - box.y
      if (window.innerWidth < 960) {
        this.tooltip.x = 0
        this.tooltip.y = tooltipY
      } else {
        const TOOLTIP_WIDTH = 260
        this.tooltip.x = Math.min(tooltipX, window.innerWidth - TOOLTIP_WIDTH)
        this.tooltip.y = tooltipY + 50
      }
      this.tooltip.index = index
      this.tooltip.text = text
    },
    hideTooltip() {
      this.tooltip.index = -1
      this.tooltip.text = null
    },
    hasNote(service, card) {
      return Boolean(service.note && card[service.note])
    },
    getNotes(service, card) {
      if (this.hasNote(service, card)) {
        return (
          {
            note1: '*',
            note2: '**',
            note3: '***',
            note4: '****',
            note5: '*****',
            note6: '******',
          }[service.note] || ''
        )
      }
      return ''
    },
  },
}

function throttle(func, ms) {
  let isThrottled = false
  let savedArgs
  let savedThis

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments
      savedThis = this
      return
    }

    func.apply(this, arguments)

    isThrottled = true

    setTimeout(function () {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}
</script>

<style lang="postcss" scoped>
.swiper-cards {
  @apply md:mx-[-10px];
  .swiper-slide {
    @apply md:px-[10px];
  }
  &:hover {
    .swiper-slide {
      @apply md:opacity-40 md:hover:opacity-100;
      transition: opacity 0.2s var(--cubic-bezier);
    }
  }
  .slide-title {
    @apply relative;
    &::before {
      content: '';
      @apply absolute bottom-[1px] h-[1px] block bg-repeat-x w-full;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' height='1' viewBox='0 0 8 1' width='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 .5h8' stroke='%23282929' stroke-dasharray='1 3'/%3E%3C/svg%3E");
    }
  }
  .swiper-wrapper {
    transition-timing-function: var(--cubic-bezier);
  }
  .swiper-slide {
    .slide-title {
      @apply text-gray-500;
      &::after {
        content: '';
        transition: width 0.8s var(--cubic-bezier);
        @apply absolute bottom-0 h-[3px] block bg-gray-100 w-0 rounded-full;
      }
    }
    /* &.swiper-slide-active .slide-title { */
    .slide-title.active {
      @apply text-gray-100;
      &::after {
        @apply w-[calc(100%_-_12px)];
      }
    }
  }
}
.tooltip {
  transition: transform 0.2s var(--cubic-bezier);
}
</style>
