<template>
  <FitnessBase
    v-if="club"
    :fitness-testing="club.fitnessTesting || false"
    :group-training="club.groupTraining || false"
    :mini-groups="club.miniGroups || false"
    :kids-club="club.kidsClub || false"
    :myzone-encore="club.myzoneEncore || false"
    :personal-training="club.personalTraining || false"
    :fitness-testing-title="title"
    :narrow="true"
  />
</template>

<script>
import FitnessBase from './FitnessBase'

export default {
  name: 'ClubFitness',
  components: {
    FitnessBase,
  },
  builder: {
    component: 'club-fitness',
    layout: 'gap-content-gap',
  },
  props: ['title'],
  computed: {
    club() {
      const clubSlug = this.$route.params.id
      return this.$store.getters['modules/content/clubs'].find(c => c.slug === clubSlug) || { title: '' }
    },
  },
}
</script>
